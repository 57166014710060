import React, { useState, useEffect } from "react";
import useAuth from "../stores/authStore";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import { api } from "../utils/axios-instance";
import { InputLabel, TextField, Tooltip } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import EditOff from "@mui/icons-material/EditOff";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

// images from public folder
const ProfilePicDummy = `${process.env.PUBLIC_URL}/assets/profilePicDummy.webp`;

const SecurityAllocation = ({
  handleSecurityAllocation,
  isOpen,
  checkboxValues,
  reciverEmail,
  setRecieverEmail,
  message,
  setMessage,
}) => {
  const listLocations = useAuth((state) => state.listLocations);
  const coordinates = useAuth((state) => state.coordinates);
  const [formData, setFormData] = useState({});
  const [currentTime, setCurrentTime] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectiveAcessData, setSelectiveAcessData] = useState({});
  const [editingUser, setEditingUser] = useState(null); // Track which user is being edited
  const [users, setUsers] = useState([]);

  useEffect(() => {
    // Fetch user data only when the component mounts or isOpen changes
    if (isOpen) {
      listLocations();
      listUsers();
    }
  }, [isOpen]);

  useEffect(() => {
    const sendDataToCallback = () => {
      handleSecurityAllocation({
        ...formData,
        selectedUsers: selectedUsers,
        selectiveAcessData: selectiveAcessData,
      });
    };

    // Fetch user data whenever formData or selectedUsers changes
    if (isOpen) {
      sendDataToCallback();
    }
  }, [formData, selectiveAcessData, isOpen]);

  useEffect(() => {
    // Set the initial current time when the component mounts
    const now = new Date();
    setCurrentTime(
      `${now.getHours().toString().padStart(2, "0")}:${now
        .getMinutes()
        .toString()
        .padStart(2, "0")}`
    );
  }, [selectedUsers]);

  const listUsers = async () => {
    try {
      const userList = await api.get(`/users/list_users`);
      // console.log("users :", userList.data);
      setUsers(userList.data);
    } catch (error) {
      console.log(error);
    }
  };

  const today = new Date().toISOString().split("T")[0];

  // const calculateTimeDifference = (selectedDate, selectedTime) => {
  //   if (!selectedDate || !selectedTime) {
  //     // Set a default time difference or handle as needed
  //     return null;
  //   }

  //   const selectedDateTime = new Date(`${selectedDate}T${selectedTime}`);
  //   const currentTime = new Date();

  //   if (selectedDateTime < currentTime) {
  //     // Handle invalid time
  //     return null;
  //   }

  //   const timeDiffInSeconds = Math.floor(
  //     (selectedDateTime - currentTime) / 1000
  //   );
  //   return timeDiffInSeconds;
  // };

  const calculateTimeDifference = (selectedDate, selectedTime) => {
    if (!selectedDate && !selectedTime) {
      // Handle the case where both date and time are not available
      return null;
    }

    const currentTime = new Date();

    if (selectedDate && selectedTime) {
      // If both date and time are available
      const selectedDateTime = new Date(`${selectedDate}T${selectedTime}`);

      if (selectedDateTime < currentTime) {
        // Handle invalid time
        return null;
      }

      return Math.floor(selectedDateTime - currentTime);
    }

    if (selectedTime) {
      // If only selectedTime is available, consider today's date
      const todayDate = new Date().toISOString().split("T")[0];
      const selectedDateTime = new Date(`${todayDate}T${selectedTime}`);

      if (selectedDateTime < currentTime) {
        // Handle invalid time
        return null;
      }

      return Math.floor((selectedDateTime - currentTime) / 1000);
    }

    // If only selectedDate is available, consider the current time
    const selectedDateTime = new Date(`${selectedDate}T00:00:00`);

    if (selectedDateTime < currentTime) {
      // Handle invalid time
      return null;
    }

    return Math.floor((selectedDateTime - currentTime) / 1000);
  };

  const handleFormdataChange = (e) => {
    const { name, value } = e.target;
    let timeDifference;

    if (name === "selectedDate") {
      // Format date consistently before updating formData
      const formattedDate = new Date(value).toISOString().split("T")[0];
      timeDifference = calculateTimeDifference(
        formattedDate,
        formData.selectedTime
      );
    } else if (name === "selectedTime") {
      timeDifference = calculateTimeDifference(formData.selectedDate, value);
    }
    console.log("timeDifference", timeDifference);
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
      timeDifference: timeDifference,
      expiresOn: timeDifference
        ? new Date(Date.now() + timeDifference)
        : new Date(Date.now() + 31536000), // Default expiration time
    }));
  };

  const transformSelectedUsersToAccessObject = (selectedUsers) => {
    const defaultAccess = ["view"]; // Customize access rights

    return selectedUsers.reduce((acc, user) => {
      acc[user.id] = {
        email: user.email,
        profilePictureUrl: user.profilePictureUrl,
        name: user.name,
        last_name: user.last_name,
        access: defaultAccess, // Add default access or customize per user
      };
      return acc;
    }, {});
  };

  // Handle opening the modal or dropdown for a specific user
  const handleEditClick = (userId) => {
    setEditingUser(userId);
  };

  // Update access for the specific user
  const handleAccessChange = (userId, accessType) => {
    console.log("userId", userId, accessType);
    setSelectiveAcessData((prevAccessData) => ({
      ...prevAccessData,
      [userId]: {
        ...prevAccessData[userId],
        access: accessType, // Replace access with the new selection (view, edit, download, etc.)
      },
    }));
  };

  const handleSelectChange = (userId, value) => {
    handleAccessChange(userId, value); // Call parent function to propagate changes
    setEditingUser(null); // Close select once user is done
  };

  const accessOptions = ["edit", "view", "download"];

  return (
    <div>
      <div className="">
        {checkboxValues.geoLocation && (
          <div>
            <p className="text-sm my-2 font-semibold">Location</p>

            <Select
              name="location"
              value={formData?.location || ""}
              onChange={(e) => handleFormdataChange(e)}
              displayEmpty
              size="small"
              fullWidth
              className="bg-white"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {coordinates.map((location) => (
                <MenuItem key={location.id} value={location.id}>
                  {location.properties.name}
                </MenuItem>
              ))}
            </Select>
          </div>
        )}

        {checkboxValues.accessControl && (
          <div>
            <p className="text-sm my-2 font-semibold">
              How do you share your file
            </p>
            <span className="flex justify-between my-4">
              <input
                type="date"
                value={today}
                disabled
                className="px-2 py-1 border-2 rounded-md bg-indigo-100 text-xs font-semibold text-gray-600 w-32"
              />
              <p>›</p>
              <input
                type="date"
                name="selectedDate"
                value={formData?.selectedDate || ""}
                min={today} // Set the minimum date to today
                onChange={(e) => handleFormdataChange(e)}
                className={`px-2 py-1 border-2 rounded-md ${
                  formData?.selectedDate ? "bg-indigo-100" : "bg-white"
                } text-xs font-semibold text-gray-600 w-32`}
              />
            </span>

            <span className="flex justify-between my-4">
              <input
                type="time"
                name="selectedTime"
                disabled
                value={currentTime}
                className="px-2 py-1 border-2 rounded-md bg-indigo-100 text-xs font-semibold text-gray-600 w-32"
              />
              <p>›</p>
              <input
                type="time"
                name="selectedTime"
                value={formData?.selectedTime || ""}
                onChange={(e) => handleFormdataChange(e)}
                className={`px-2 py-1 border-2 rounded-md ${
                  formData?.selectedTime ? "bg-indigo-100" : "bg-white"
                } text-xs font-semibold text-gray-600 w-32`}
              />
            </span>
          </div>
        )}

        <div className="my-2">
          <p className="text-gray-600 font-semibold my-1">Receiver</p>
          <Select
            multiple
            value={selectedUsers.map((user) => user.id)}
            onChange={(event) => {
              const selectedUserIds = event.target.value;
              const selectedUserObjects = users.filter((user) =>
                selectedUserIds.includes(user.id)
              );

              const accessObject =
                transformSelectedUsersToAccessObject(selectedUserObjects);

              setSelectiveAcessData(accessObject);
              setSelectedUsers(selectedUserObjects);
            }}
            displayEmpty
            size="small"
            fullWidth
            renderValue={(selected) => (
              <div>
                {selected.map((userId) => {
                  const { name, last_name, profilePictureUrl } = users.find(
                    (user) => user.id === userId
                  );

                  return (
                    <Chip
                      key={userId}
                      avatar={
                        <img
                          src={profilePictureUrl}
                          alt={`${name}'s Profile Pic`}
                          style={{
                            borderRadius: "50%",
                            width: "24px",
                            height: "24px",
                          }}
                        />
                      }
                      label={
                        <div className="flex gap-1">{`${name} ${last_name}`}</div>
                      }
                      className="mx-1"
                    />
                  );
                })}
              </div>
            )}
          >
            <MenuItem value="" disabled>
              <p>Select a user</p>
            </MenuItem>
            {users.length > 0 &&
              users.map((user) => (
                <MenuItem
                  key={user.id}
                  value={user.id}
                  style={{
                    borderRadius: "10px",
                  }}
                >
                  <span className="flex justify-between items-center w-full">
                    <span className="flex flex-row items-center gap-2">
                      <img
                        src={
                          user.profilePictureUrl
                            ? user.profilePictureUrl
                            : ProfilePicDummy
                        }
                        alt="Profile pic"
                        className="h-8 w-8 rounded-full"
                      />
                      <span>
                        <p className="text-sm font-semibold">
                          {user.name} {user.last_name}
                        </p>
                        <p className="text-xs font-light text-gray-500">
                          {user.email}
                        </p>
                      </span>
                    </span>
                    <p className="text-sm font-semibold">Invite › </p>
                  </span>
                </MenuItem>
              ))}
          </Select>
          {/* 
          {selectedUsers.map((user, index) => (
            <div
              key={index}
              className="flex justify-between my-2 bg-[#F0F0F0] p-2 rounded-md"
            >
              <p className="text-sm font-semibold text-black">
                {user.name} {user.last_name}
              </p>

              <span className="flex flex-row gap-4 items-center">
                <img
                  src={
                    user.profilePictureUrl
                      ? user.profilePictureUrl
                      : ProfilePicDummy
                  }
                  alt="Profile pic"
                  className="h-6 w-6 rounded-md"
                />
                <p className="text-xs font-medium text-indigo-900 bg-indigo-200 p-1 rounded-md">
                  View
                </p>
              </span>
            </div>
          ))} */}

          {selectedUsers.map((user, index) => (
            <div
              key={index}
              className="flex justify-between my-2 bg-[#F0F0F0] p-2 rounded-md items-center"
            >
              <div className="flex gap-3 items-center">
                <img
                  src={user.profilePictureUrl || "path/to/placeholder.jpg"}
                  alt="Profile pic"
                  className="h-6 w-6 rounded-md"
                />
                <p className="text-sm font-semibold text-black">
                  {user.name} {user.last_name}
                </p>
              </div>

              <span className="flex flex-row gap-4 items-center">
                {editingUser === user.id ? (
                  <Select
                    value={selectiveAcessData[user.id].access || []}
                    multiple
                    onChange={(e) =>
                      handleSelectChange(user.id, e.target.value)
                    }
                    size="small"
                    renderValue={(selected) => (
                      <div>
                        {selected.map((value) => (
                          <Chip
                            key={value}
                            label={value}
                            className="mr-1 capitalize"
                            size="small"
                          />
                        ))}
                      </div>
                    )}
                    MenuProps={{
                      PaperProps: {
                        style: { backgroundColor: "#EDEDFC" }, // Light indigo background
                      },
                    }}
                  >
                    {accessOptions.map((option) => (
                      <MenuItem key={option} value={option}>
                        <p className="mr-1 capitalize">{option}</p>
                        {selectiveAcessData[user.id]?.access.includes(
                          option
                        ) && <DoneIcon fontSize="small" />}
                      </MenuItem>
                    ))}
                  </Select>
                ) : (
                  <>
                    {selectiveAcessData[user.id]?.access.map(
                      (access, index) => {
                        const colorString =
                          access === "edit"
                            ? "bg-[#FFEDEF] text-[#D1293D]"
                            : access === "view"
                              ? "bg-[#EDEDFC] text-[#5E5ADB]"
                              : "bg-[#E1FCEF] text-[#14804A]";
                        return (
                          <p
                            className={`capitalize text-sm font-semibold p-2 rounded-sm ${colorString}`}
                          >
                            {access}
                          </p>
                        );
                      }
                    )}
                  </>
                )}

                {editingUser === user.id ? (
                  <IconButton
                    onClick={() => handleEditClick(null)}
                    size="small"
                  >
                    <EditOff />
                  </IconButton>
                ) : (
                  <IconButton
                    onClick={() => handleEditClick(user?.id)}
                    size="small"
                  >
                    <EditIcon />
                  </IconButton>
                )}
              </span>
            </div>
          ))}
        </div>

        {/* Share through email */}
        <div className="my-2">
          <div className="w-full">
            <InputLabel className="text-gray-800 font-semibold my-1" id="email">
              Share via Email
              <Tooltip title="This will send an email to the recipient with the file attached, but this is an insecure method of sharing (does not include passwords set on the platform).">
                <b className="text-gray-500 font-serif text-xs border-2 border-gray-500 rounded-full py-0 px-2 ml-2">
                  i
                </b>
              </Tooltip>
            </InputLabel>
            <span id="email" className="flex flex-row gap-2">
              <TextField
                id="outlined-basic-email"
                variant="outlined"
                className="w-full"
                placeholder="Enter reciever's Email here"
                name="email"
                onChange={(e) => setRecieverEmail(e.target.value)}
                value={reciverEmail}
                size="small"
              />
              <TextField
                id="outlined-basic-message"
                variant="outlined"
                className="w-full"
                placeholder="Enter message here"
                name="message"
                onChange={(e) => {
                  console.log("here bro here", typeof setMessage);
                  setMessage(e.target.value);
                }}
                value={message}
                size="small"
              />
            </span>
          </div>

          {/* {selectedUsers.map((user, index) => (
            <div
              key={index}
              className="flex justify-between my-2 bg-[#EDEDFC] p-2 rounded-md"
            >
              <p className="text-sm font-medium text-indigo-700">
                {user.name} {user.last_name}
              </p>

              <span className="flex flex-row gap-4 items-center">
                <img
                  src={
                    user.profilePictureUrl
                      ? user.profilePictureUrl
                      : ProfilePicDummy
                  }
                  alt="Profile pic"
                  className="h-6 w-6 rounded-md"
                />
                <p className="text-xs font-medium text-green-900 bg-green-100 p-1 rounded-md">
                  Can Edit
                </p>
              </span>
            </div>
          ))} */}
        </div>
      </div>
    </div>
  );
};

export default SecurityAllocation;
